a {
    cursor: pointer;
    text-decoration: none;
}

.location-page {
    background: #fff;
    padding-top: 3rem;
}

.loc-wrapper {
    /* background: #fff; */
    max-width: 85%;
    margin: auto;
}


@media screen and (max-width: 600px) {
    .loc-wrapper {
        max-width: 90%;
        margin: auto;
    }
}

.loc-wrapper .loc {
    display: flex;
    flex-direction: column;
    padding-top: 4rem;
}

.loc-wrapper .loc .loc-1 {
    font-size: var(--head_font);
    font-weight: 700;
    color: var(--primary_color);
}


.loc-wrapper .loc .loc-2 a {
    /* font-size: 16px; */
    font-weight: 700;
    justify-content: flex-start;
    padding: 0;
    padding-top: 1rem;
    color: var(--black_color);
}

@media screen and (max-width: 950px) {
    .loc-wrapper .loc .loc-1 {
        font-size: 50px;
        text-align: center;
    }

    .loc-wrapper .loc .loc-2 {
        text-align: center;
        max-width: 80%;
        margin: auto;
        font-size: 30px;
    }
}

@media screen and (max-width: 600px) {
    .loc-wrapper .loc .loc-1 {
        font-size: 30px;
    }

    .loc-wrapper .loc .loc-2 {
        font-size: 25px;
        padding-top: 1rem;
    }
}

.container {
    max-width: 85%;
    margin: auto;
}

/* campus styles */


.campus-wrapper {
    display: flex;
    justify-content: center;
    /* align-items: center; */
    flex-direction: column;
    gap: 3em;
    padding-bottom: 5rem;
    margin-top: 2rem;
}

.campus-wrapper .campus-1 {
    display: grid;
    grid-template-columns: repeat(3,1fr);
    justify-content: space-between;
    align-items: flex-start;
    gap: 3em;
    /* margin-top: 2rem; */
}

.campus-wrapper .campus-1 .city{
    text-transform: uppercase;
}

@media screen and (max-width: 950px) {
    .campus-wrapper .campus-1 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        /* align-items: center; */
        gap: 2em;
        max-width: 100%;
        margin: auto;
    }

    .campus-wrapper {
        margin-top: 3rem;
    }

}

.campus-wrapper .campus {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    border-bottom: var(--black_color) solid 2px;
    width: 350px;
    /* height: 420px; */
    height: 320px;

}

@media screen and (max-width: 850px) {
    .campus-wrapper .campus {
        /* height: 750px; */
        border-bottom: 100%;
        width: 100%;
        margin: auto;
    }
}


@media screen and (max-width: 950px) {
    .campus-wrapper .campus {
        /* height: 600px; */
        border-bottom: 100%;
        width: 100%;
        margin: auto;
        margin-bottom: 3rem;
    }

}

@media screen and (min-width: 851px) {
    .campus-wrapper .campus {
        /* height: 850px; */
        /* height: 850px; */
        border-bottom: 100%;
        /* width: 90%;
    margin: auto; */
        margin-bottom: 1rem;
    }

}

.city {
    font-size: 30px;
    font-weight: 700;
    color: var(--black_color);
}

.city-1 {
    font-size: 30px;
    font-weight: 700;
    color: var(--black_color);
    margin-bottom: 2rem;
}

.broad-1 {
    font-size: 20px;
    font-weight: 700;
    color: var(--black_color);
    /* margin-bottom: 5rem; */
    /* margin: 1.5rem 0; */
}

.add {
    /* font-size: 25px; */
    font-weight: 600;
    color: var(--primary_color);
    margin-top: 1.5rem;
    margin-bottom: 1rem;
}

.add:hover {
    /* background: #B5192A; */
    color: var(--white-color);
    padding: 1.5rem auto;
}

.ser {
    font-size: 20px;
    font-weight: 600;
    color: var(--black_color);
    /* margin-top: 1.5rem; */
    /* margin-bottom: 1.5rem; */
}

.day {
    font-size: 16px;
    font-weight: 600;
    color: var(--black_color);
    margin: 0.5rem 0;
    /* margin-bottom: 1.5rem; */
}

.day-1 {
    font-size: 16px;
    font-weight: 400;
    color: var(--black_color);
    margin-bottom: 0.75rem;
    /* margin-bottom: 2rem; */
}

.info a {
    font-size: 16px;
    font-weight: 700;
    color: var(--black_color);
    justify-content: flex-start;
    /* margin-top: 1.5rem; */
    padding: 0;
    margin-bottom: 1.5rem;
}

/* 
.info a:hover {
    color: #B5192A;
} */