#serve_showcase {
  height: 35rem;
  background: url("../../assets/images/church/IMG_8953.jpg") no-repeat center center/cover;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

#serve_showcase::before {
  content: "";
  position: absolute;
  inset: 0;
  background: rgba(0, 0, 0, 0.1);
  z-index: 0;
}

#serve_showcase h1 {
  font-size: 4rem;
  color: #fff;
  z-index: 1;
}

/* ======= SERVE_WHERE ======= */
#serve_where {
  min-height: 20rem;
  display: flex;
  margin: 3rem auto;
  margin-right: 0;
  max-width: 85%;
  gap: 2rem;
}

#serve_where .right,
#serve_where .left {
  max-width: 100%;
}

#serve_where .left {
  width: 50%;
}

#serve_where .right {
  position: absolute;
  top: -7rem;
}

#serve_where .right {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

#serve_where .where_text {
  background-color: var(--primary_color);
  min-height: 200px;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 4rem 1rem 4rem 2rem;
}

#serve_where .where_text p {
  color: #fff;
  font-weight: 600;
  font-size: var(--head_font);
  line-height: 1.2;
}

#serve_where .shape {
  display: block;
  width: 100%;
  height: 15rem;
  background: rgb(146, 146, 146);
  clip-path: polygon(0 0, 100% 0, 100% 100%, 15% 100%);
  position: absolute;
  bottom: -15px;
  left: 0;
  z-index: -1;
}

/* ======= SECTION: SERVE_CARDS ======= */
#serve_cards .cards_wrapper {
  max-width: 950px;
  margin: 5rem auto;
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: 1fr 1fr;
}

#serve_cards .s_card {
  padding-bottom: 1rem;
  margin-bottom: 2rem;
  border-bottom: 2px solid #9b9b9b;
}

#serve_cards .cards_img img {
  height: 300px;
  border-radius: 7px;
}

#serve_cards .bottom .links {
  display: flex;
  gap: 2rem;
}

#serve_cards .cards_wrapper .bottom h3 {
  font-size: 2rem;
  text-transform: capitalize;
}

#serve_cards .cards_wrapper .bottom a {
  color: #000;
  padding: 0;
  font-weight: 600;
  text-transform: uppercase;
}

#serve_cards .cards_wrapper .bottom a:hover,
#serve_cards .cards_wrapper .bottom a:focus {
  color: var(--primary_color);
}

#serve_cards .details {
  padding-top: 1rem;
  height: 0;
  overflow: hidden;
  transition: height ease 0.3s;
}

#serve_cards .details.reveal {
  height: auto;
}

#serve_cards .details p {
  color: #242424;
}

/* ======= MEDIA QUERY ======= */
@media screen and (max-width: 1000px) {
  #serve_where {
    flex-direction: column-reverse;
    margin-right: auto;
    margin-top: 0;
  }

  #serve_where .shape {
    left: unset;
  }

  #serve_where .left {
    width: 100%;
  }

  #serve_where .right {
    top: -4rem;
  }

  /* ======= SECTION: SERVE_CARDS ======= */
  #serve_cards .cards_wrapper {
    max-width: 85%;
  }

  #serve_cards .cards_img img {
    height: auto;
  }
}

@media screen and (max-width: 600px) {
  #serve_where {
    max-width: 100%;
    padding: 1rem;
  }

  #serve_where .where_text {
    padding: 1rem;
    min-height: 130px;
  }

  #serve_where .where_text p {
    font-size: 2rem;
  }

  /* ======= SECTION: SERVE_CARDS ======= */
  #serve_cards .cards_wrapper {
    max-width: 100%;
    padding: 1rem;
    grid-template-columns: 1fr;
  }

  #serve_cards .cards_wrapper .bottom h3 {
    font-size: 1.7rem;
  }
}