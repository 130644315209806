/* showcase */

.min-group-showcase {
  background: url("../../assets/images/church/IMG_8843.jpg") no-repeat center center/cover;
  height: 90vh;
}

.min-group-case {
  background: rgba(0, 0, 0, 0.6);
  height: 100%;
}

.min-group-txt {
  color: #fff;
  text-align: center;
  font-weight: 700;
  position: absolute;
  top: 40%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}

/* @media screen and (max-width: 600px) {
    .min-group-showcase {
        height: 70vh;
    }
} */

/* showcase end */

.kidsWrapper {
  width: 100%;
  min-height: 40vh;
  display: flex;
  justify-content: space-between;
  gap: 2%;
  padding: 4rem 2%;
}

@media screen and (max-width:600px) {
  .kidsWrapper {
    flex-direction: column;
  }
}

.kidsWrapper .col {
  flex: 1;
  height: 25rem;
  position: relative;
}

.kidsWrapper .col .txtContent h1 {
  text-transform: uppercase;
  font-size: 3rem;
}

.kidsWrapper .col .imgHolder {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: relative;

}

.kidsWrapper .col .imgHolder img {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  position: relative;
}

.kidsWrapper .col .imgHolder:hover img {
  filter: saturate(0);
}

.kidsWrapper .col .imgHolder .imgOverlay {
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: var(--secondary-color);
  opacity: .8;
  top: 0;
  left: 0;
  position: absolute;
  visibility: hidden;
  padding: 1rem;
}

.kidsWrapper .col .imgHolder .imgOverlay p{
 
  color: #fff;
  font-size: medium;
}

.kidsWrapper .col .imgHolder:hover .imgOverlay {
  visibility: visible;
  cursor: pointer;
  transition: transform var(--transition);
}

.kidsWrapper .col .imgTitle h2 {
  text-transform: uppercase;
  font-size: medium;
  color: var(--light-gray);
  padding: 0.5rem 0;
}

.connect {
  min-height: 60vh;
  background-color: var(--grey_color);
  margin-top: 2rem;
}

.connect .container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 59vh;
  gap: 4%;
}

@media screen and (max-width:600px) {
  .connect .container {
    flex-direction: column;
  }
}

.connect .container .col {
  flex: 1;
  /* border: solid red 2px; */
}

.connect .container .col .contentWrapper h1 {
  color: #fff;
  text-transform: uppercase;
  font-size: 3rem;
}

.connect .container .col .contentWrapper h4 {
  color: #fff;
  font-size: 1.5rem;
}

.connect .container .col .contentWrapper .btn {
  border-color: #fff;
  color: #fff;
}



.connect .container .col .contentWrapper .bottom .social {
  display: flex;
}

.connect .container .col .contentWrapper .bottom .social a {
  font-size: 2rem;
  padding: 1rem 1rem 1rem 0;
  transition: var(--transition);
}

.connect .container .col .contentWrapper .bottom .social a:hover,
.connect .container .col .contentWrapper .bottom .social a:focus {
  color: var(--primary_color);
}

.whoWeAre {
  min-height: 60vh;
  margin: 4rem 0;
}

.whoWeAre .container {
  display: flex;
  gap: 4%;
}

@media screen and (max-width:600px) {
  .whoWeAre .container {
    flex-direction: column;
  }
}

.whoWeAre .container .col {
  flex: 1;
}

.whoWeAre .container .col .contentWrapper .imgHolder {
  width: 100%;
  height: 100%;
}

.whoWeAre .container .col .contentWrapper .imgHolder img {
  filter: saturate(0);
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.whoWeAre .container .col .contentWrapper .imgHolder img:hover {
  filter: unset;
}

.whoWeAre .container .col .contentWrapper h1 {
  text-transform: uppercase;
  font-size: 3rem;
}

.whoWeAre .container .col .contentWrapper p {
  width: 70%;
  margin-top: 2rem;
}

.upcomingEvents {
  background-color: #efefef;
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.upcomingEvents .container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}



.upcomingEvents .container h1 {
  text-transform: uppercase;
  font-size: 3rem;
  color: var(--secondary-color);
}

.upcomingEvents .container .eventsList {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

@media screen and (max-width:600px) {
  .upcomingEvents .container {
    flex-direction: column;
  }

  .upcomingEvents .container .eventsList {

    grid-template-columns: repeat(1, 1fr);
  }
}

.upcomingEvents .container .eventsList .eventItem {
  position: relative;
  font-weight: 500;
  padding: 0.75rem 2.3rem;
  margin: 0.75rem;
  border: 1px solid var(--secondary-color);
  transition: transform var(--transition);
  transition: all .3s ease-in;
  color: var(--grey_color);
  text-transform: uppercase;
  min-width: 20rem;
  text-align: center;
}

.upcomingEvents .container .eventsList .eventItem:hover {
  color: #fff;
  background-color: var(--secondary-color);
}

.upcomingEvents .container .eventsList .eventItem::before {
  content: "";
  position: absolute;
  z-index: -1;
  inset: 0;
  padding: 0.75rem 2.3rem;
  background-color: var(--secondary-color);
  transform-origin: top;
  transition: transform var(--transition);
  transform: scaleX(0);
}

.upcomingEvents .container .eventsList .eventItem:hover::before,
.upcomingEvents .container .eventsList .eventItem:focus::before {
  transform: scaleX(1);
}

.upcomingEvents .container .links {
  color: var(--grey_color);
}

/* kids cards section */

.kids-cards-wrapper {
  background: #fff;
  position: relative;
}

.kids-cards-case {
  max-width: 85%;
  margin: auto;
}

.kids-cards-show {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.kids-cards-1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* cursor: pointer; */
  width: 31%;
  transition: all 0.3s ease-in-out;
}

.kdt {
  width: 100%;
}

.kc-a {
  background: url("../../assets/images/x2.jpeg") no-repeat center center/cover;
  /* background: url('../../assets/images/tddd1.jpg') no-repeat center center/cover; */
  height: 27rem;
  width: 100%;
  position: relative;
  top: -6rem;
  transition: all 0.3s ease-in;
}

.kc-a2 {
  background: url("../../assets/images/x3.jpeg") no-repeat center center/cover;
  /* background: url('../../assets/images/tddd2.jpg') no-repeat center center/cover; */
  height: 27rem;
  width: 100%;
  position: relative;
  top: -6rem;
  transition: all 0.3s ease-in;
}

.kc-a3 {
  background: url("../../assets/images/x4.jpeg") no-repeat center center/cover;
  height: 27rem;
  width: 100%;
  position: relative;
  top: -6rem;
  transition: all 0.3s ease-in-out;
}

.kk-s {
  clip-path: polygon(0 0, 100% 0, 80% 100%, 0 100%);
  background: var(--primary_color);
  height: 3rem;
  width: 60%;
  position: absolute;
  top: 2rem;
  left: 0;
}

/* .kids-cards-1:hover .kk-s-over {
    background: rgba(208, 47, 47, 0.6);
    height: 100%;
    transition: all .3s ease-in;
} */

.kk-txt {
  font-size: 20px;
  color: #fff;
  font-weight: 800;
  position: absolute;
  top: 2.5rem;
  left: 10px;
}

.kc-b {
  background: #414141;
  height: 16rem;
  width: 100%;
  position: relative;
  top: -6.5rem;
}

.kc-b {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-align: center;
}

.kk-txt-1 {
  font-size: 1.3rem;
  font-weight: 700;
  line-height: 2;
  margin-bottom: 1rem;
}

.kk-txt-2 {
  font-size: 1.7rem;
  font-weight: 700;
  color: var(--primary_color);
}

/* .kids-cards-1:hover .kc-b {
    background: var(--primary_color);
    transition: all .2s ease-in;
}

.kids-cards-1:hover .kk-txt-2 {
    color: #fff;
    transition: all .2s ease-in;
} */

@media screen and (max-width: 950px) {
  .kids-cards-show {
    flex-direction: column;
    justify-content: center;
  }

  .kids-cards-1 {
    width: 100%;
    margin-bottom: 2rem;
  }

  /* .kk-s {
        width: 50%;
    } */
}

@media screen and (max-width: 600px) {
  .kk-s {
    width: 70%;
  }

  .kk-txt-2 {
    font-size: 1.5rem;
  }
}

/* kids cards section end */

/* kids outreach */

.kid-out-wrapper {
  margin-bottom: 5rem;
}

.kids-out-show {
  max-width: 85%;
  margin: auto;
}

.kids-out {
  display: flex;
  justify-content: center;
  align-items: center;
}

.kids-aa {
  width: 65%;
  display: flex;
  flex-direction: column;
  /* cursor: pointer; */
}

.ka-case {
  background: url("../../assets/images/glll.jpg") no-repeat center center/cover;
  height: 22rem;
  position: relative;
}

.ka-s {
  clip-path: polygon(0 0, 100% 0, 80% 100%, 0 100%);
  background: var(--primary_color);
  height: 3rem;
  width: 45%;
  position: absolute;
  top: 2rem;
  left: 0;
}

.ka-txt {
  font-size: 20px;
  color: #fff;
  font-weight: 800;
  position: absolute;
  top: 2.5rem;
}

/* .kids-out:hover .ka-over {
    background: rgba(208, 47, 47, 0.6);
    height: 100%;
    transition: all .2s ease-in;
} */

.kids-bb {
  width: 35%;
  background: #414141;
  height: 22rem;
}

.kb-show {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  /* cursor: pointer; */
}

.kb-txt-1 {
  color: #fff;
  font-size: 20px;
  font-weight: 700;
  margin-top: 3rem;
  margin-bottom: 2rem;
}

.kb-txt-2 {
  color: var(--primary_color);
  font-size: 30px;
  font-weight: 700;
  text-transform: uppercase;
}

/* .kids-out:hover .kids-bb {
    background: var(--primary_color);
    transition: all .2s ease-in;
}
.kids-out:hover .kb-txt-2 {
    color: #fff;
    transition: all .2s ease-in;
} */

@media screen and (max-width: 950px) {
  .kids-out {
    flex-direction: column;
  }

  .kids-aa {
    width: 100%;
  }

  .kids-bb {
    width: 100%;
    height: 15rem;
  }

  .kb-txt-2 {
    font-size: 25px;
  }

  .ka-s {
    width: 50%;
  }
}

@media screen and (max-width: 600px) {
  .kb-txt-2 {
    font-size: 22px;
  }

  .ka-s {
    width: 65%;
  }
}

/* kids outreachend */

/* select campus  */

.sel-cam-wrapper {
  margin-bottom: 7rem;
}

.sel-cam-case {
  max-width: 85%;
  margin: auto;
}

.sel-cam {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.sc-a {
  font-size: 20px;
  font-weight: 700;
  color: #000;
  text-align: center;
  margin-bottom: 1rem;
}

.sc-b {
  font-size: 50px;
  font-weight: 700;
  color: #000;
  text-align: center;
}

@media screen and (max-width: 700px) {
  .sc-b {
    font-size: 30px;
  }
}

/* select campus end */

/* kids with special needs  */

.special-wrapper {
  margin-bottom: 6rem;
}

.special-case {
  max-width: 85%;
  margin: auto;
}

.special {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1em;
}

.spec-a {
  font-size: 30px;
  font-weight: 700;
  color: #000;
  /* margin-bottom: 1rem; */
  text-align: center;
}

.spec-b {
  font-size: 16px;
  font-weight: 500;
  color: #000;
  /* margin-bottom: 1rem; */
  text-align: left;
  width: 100%;
}

.spec-c .linkAnima {
  color: #000;
  padding: 0;
  justify-content: flex-start;
  font-weight: 700;
}

.spec-c .linkAnima:hover {
  color: var(--primary_color);
}

@media screen and (max-width: 600px) {
  .spec-a {
    font-size: 22px;
  }
}

/* kids with special needs end */

/* ministry events */

.min-eve-wrapper {
  max-width: 85%;
  margin: auto;
}

.min-eve-txt {
  font-size: 40px;
  color: #000;
  font-weight: 800;
  text-align: center;
  margin-bottom: 4rem;
}

@media screen and (max-width: 800px) {
  .min-eve-txt {
    font-size: 30px;
  }
}

/* ministry events end */

/* seminar card  */

.seminar-wrapper {
  width: 100%;
  margin: auto;
}

.seminar-case {
  margin-bottom: 12rem;
}

.seminar {
  display: flex;
  /* justify-content: flex-start; */
  justify-content: center;
  position: relative;
  width: 100%;
}

.sem-a {
  clip-path: polygon(0 0, 100% 0%, 79% 100%, 0% 100%);
  height: 270px;
  width: 30%;
  background-color: var(--primary_color);
  position: absolute;
  left: 0;
  top: 6rem;
}

.sem-b img {
  object-fit: cover;
  border-radius: 7px;
  width: 520px;
}

.sem-b img:hover {
  scale: 1.1;
  transition: all 0.3s ease-in-out;
  z-index: 4;
  cursor: pointer;
}

.sem-b {
  position: absolute;
  /* left: 10rem; */
  left: 10%;
  top: 0.2rem;
}

.sem-c {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 10px 20px 14px rgba(0, 0, 0, 0.42);
  position: relative;
  right: -11rem;
  top: 2rem;
  /* width: 500px; */
  width: 35%;
  height: 290px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.sem-c:hover {
  scale: 1.1;
  transition: all 0.2s ease-in-out;
}

.sem-c-1 {
  background: #efefef;
  width: 90%;
  height: 100%;
}

.sem-c-2 {
  background: rgb(255, 255, 255);
  width: 10%;
  height: 100%;
}

/* .sem-c-txt-1 {
  font-size: 20px;
  font-weight: 700;
  margin-left: 2rem;
  margin-top: 2rem;
  margin-bottom: 0.7rem;
}

.sem-c-txt-2 {
  font-size: 18px;
  font-weight: 600;
  margin-left: 2rem;
  margin-bottom: 0.5rem;
} */

.sem-c-txt-3 {
  font-size: 16px;
  font-weight: 600;
  margin-left: 2rem;
  margin-right: 1rem;
  transform: translateY(170%);
}

.sem-c-txt-4 .linkAnima {
  padding: 0;
  justify-content: flex-start;
  color: #000;
  margin-left: 2rem;
  margin-top: 1rem;
}

.sem-c-txt-4 .linkAnima:hover {
  color: var(--primary_color);
}

@media screen and (max-width: 960px) {
  .seminar {
    flex-direction: column;
    justify-content: center;
  }

  .seminar-case {
    margin-bottom: 27rem;
  }

  .sem-c {
    right: -8rem;
    top: 19rem;
    width: 65%;
    /* flex-direction: column; */
  }

  .sem-b img {
    width: 80%;
  }
}

@media screen and (max-width: 895px) {
  .sem-c {
    right: -9rem;
    top: 22rem;
    width: 60%;
    /* flex-direction: column; */
  }
}

@media screen and (max-width: 761px) {
  .sem-c {
    right: -7rem;
    top: 18rem;
    width: 60%;
  }
}

@media screen and (max-width: 700px) {
  .sem-c {
    right: -5.4rem;
    top: 17rem;
    width: 70%;
    height: 250px;
  }

  .sem-c-txt-3 {
    transform: translateY(70%);
  }

  .sem-b img {
    width: 86%;
  }

  .sem-a {
    width: 60%;
    height: 130px;
  }
}

@media screen and (max-width: 600px) {
  .seminar-case {
    margin-bottom: 14rem;
  }

  .sem-c {
    right: -3.3rem;
    top: 10rem;
    width: 70%;
    height: 250px;
  }

  .sem-b img {
    width: 86%;
  }

  .sem-a {
    width: 60%;
    height: 130px;
  }
}

/* seminar card end  */

/* ministry director  */

.min-dir-case {
  width: 100%;
  background: #efefef;
  margin-bottom: 5rem;
}

.min-dir {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  /* height: 400px; */
}

.mindir-a {
  width: 45%;
}

.mindir-a img {
  object-fit: cover;
  clip-path: polygon(0 0, 100% 0%, 79% 100%, 0% 100%);
  width: 100%;
  /* height: 100%; */
  height: 500px;
}

.mindir-b {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 55%;
}

.dir-a {
  font-size: 2rem;
  color: #000;
  font-weight: 700;
}

.dir-b {
  /* font-size: 1.3rem; */
  color: #000;
  font-weight: 600;
  margin: 1rem 0;
}

.dir-c .linkAnima {
  color: #000;
  padding: 0;
  justify-content: flex-start;
  font-weight: 700;
}

.dir-c .linkAnima:hover {
  color: var(--primary_color);
}

@media screen and (max-width: 950px) {
  .min-dir-case {
    width: 85%;
    margin: auto;
    background: #fff;
  }

  .min-dir {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .mindir-a {
    width: 100%;
    height: 300px;
  }

  .mindir-b {
    width: 100%;
    background: #efefef;
    padding-top: 2rem;
    padding-left: 2rem;
    padding-bottom: 3rem;
  }
}

/* ministry director end */