#faq {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  line-height: 1.3;
  margin-top: 2rem;
}

#faq .faq_wrapper {
  width: 70%;
  margin-top: 2rem;
  /* padding-bottom: 3rem; */
}

.faq_wrapper h1 {
  font-size: 2.5rem;
  line-height: 1;
  margin-top: 1rem;
  text-transform: capitalize;
}

/* .faq_wrapper .accordion {
  width: 100%;
  border-bottom: 1px solid #a1a1a1;
}

.faq_wrapper .accordion p {
  font-size: 1.2rem;
}

.faq_wrapper .question {
  font-weight: 700;
  padding: 15px 0;
  cursor: pointer;
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.faq_wrapper .question span {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px;
  transition: transform var(--transition);
}

.faq_wrapper .question .activated {
  transform: rotate(180deg);
}

.faq_wrapper .question p {
  transition: color var(--transition);
}

.faq_wrapper .question:hover p,
.faq_wrapper .question:focus p {
  color: var(--primary_color);
}

.faq_wrapper .accordion .dropdown {
  transition: all 300ms cubic-bezier(0, 1, 0, 1);
}

.faq_wrapper .accordion .dropdown.drop {
  height: 0;
  overflow: hidden;
  transition: all 300ms cubic-bezier(0, 1, 0, 1);
}

.faq_wrapper .accordion .dropdown p {
  padding: 10px 20px 10px 0;
  font-size: 1rem;
} */

/* ======= MEDIA QUERY ======= */
@media screen and (max-width: 600px) {
  #faq .faq_wrapper {
    padding: 0 2rem;
    width: 100%;
  }

  .faq_wrapper h1 {
    font-size: 2rem;
  }
}
