.form_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border: 3px solid #fff;
  min-width: 700px;
  height: 47px;
  padding: 0px 1px 1px 0px;
  margin-top: 1rem;
}

.form_container p {
  background-color: #fff;
  color: #000;
  height: 100%;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 1.4rem;
}

.form_container .input_area {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
}

.form_container .input_area input {
  position: relative;
  width: 100%;
  height: 100%;
  font-size: 1.2rem;
  padding-left: 1.5rem;
  color: #fff;
  border: 0;
  outline: none;
  background: transparent;
}

.form_container .input_area .placeholder {
  position: absolute;
  color: #fff;
  font-weight: 700;
  font-size: 1.2rem;
  padding-left: 1.5rem;
  pointer-events: none;
}

.form_container .input_area input:focus ~ .placeholder,
.form_container .input_area input:valid ~ .placeholder {
  display: none;
}

.form_container .arrow_icon {
  /* width: 5%; */
  height: 100%;
  background-color: #fff;
  color: var(--primary_color);
  font-size: 55px;
  cursor: pointer;
}

/* ======= MEDIA QUERY ======= */
@media screen and (max-width: 600px) {
    .form_container {
        min-width: 200px;
    }

  .form_container p {
    display: none;
  }

  .form_container .input_area .placeholder {
    font-size: 1rem;
    font-weight: 500;
  }
}
