.slider_wrapper {
  background-color: var(--black_color);
  min-height: 30rem;
  line-height: 1.3;
  width: 100%;
  padding-bottom: 4rem;
}

.slider_wrapper .slider_top {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 3rem;
  color: #fff;
}

.slider_wrapper .slider_top .buttons {
  display: flex;
  gap: 1rem;
}

.slider_wrapper .buttons .arrow_btn {
  width: 40px;
  height: 40px;
  border: 1px solid #464545;
  border-radius: 5px;
  font-size: 2rem;
  cursor: pointer;
  transition: color var(--transition);
}

.slider_wrapper .slider_top .arrow_btn:hover {
  color: var(--primary_color);
  border-color: var(--primary_light);
}

.slider_wrapper .card {
  max-width: 300px;
  margin-left: 0 1rem;
  transition: background-color var(--transition);
}

.slider_wrapper .card .card_top {
  position: relative;
  display: flex;
  align-items: center;
}

.slider_wrapper .card .card_top img {
  border-radius: 5px 5px 0 0;
  height: 220px;
  object-fit: cover;
}

.slider_wrapper .card_top .text_on_img {
  position: absolute;
  right: 1.5rem;
  width: 200px;
  line-height: 1.2;
  font-size: 1.5rem;
  text-transform: uppercase;
  text-align: right;
}

.slider_wrapper .card .title,
.slider_wrapper .card .head,
.slider_wrapper .card .name {
  padding-bottom: 0.3rem;
  transition: var(--transition);
}

.slider_wrapper .card .title {
  text-transform: uppercase;
  color: #cccc;
}

.slider_wrapper .card .head {
  color: var(--primary_color);
  color: #fff;
  font-weight: 500;
  font-size: 1.3rem;
  text-transform: capitalize;
  transition: color var(--transition);
}

.slider_wrapper .card .name {
  text-transform: capitalize;
  color: #f4f4f4;
}

.slider_wrapper .card .card_bottom {
  font-size: 50rem;
  color: #fff;
  padding: 1rem;
  background-color: var(--dark-grey);
  height: 210px;
  width: 100%;
  transition: background var(--transition);
}

.slider_wrapper .card:hover .card_bottom {
  background-color: var(--primary_color);
}

.slider_wrapper .card:hover .card_bottom .head {
  color: #fff;
}

@media screen and (max-width: 770px) {
  .slider_wrapper .card {
    max-width: 100%;
    margin: auto;
  }

  .slider_wrapper .card .card_top img {
    height: 250px;
  }
}