#faq1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  line-height: 1.3;
  /* margin: 2rem 0; */
}

#faq1 .faq-wrapper {
  width: 100%;
  margin: auto;
  padding-bottom: 3rem;
}

.faq-wrapper h1 {
  font-size: 3rem;
  line-height: 1;
  margin-bottom: 1rem;
}

.faq-wrapper .accordion {
  width: 100%;
  border-bottom: 1px solid #a1a1a1;
  transition: var(--transition);
}

.faq-wrapper .accordion p {
  font-size: 1.2rem;
}

.faq-wrapper .question {
  font-weight: 700;
  padding: 15px 0;
  cursor: pointer;
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.faq-wrapper .question span {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px;
  transition: transform var(--transition);
}

.faq-wrapper .question .activated {
  transform: rotate(180deg);
}

.faq-wrapper .question p {
  transition: color var(--transition);
}

.faq-wrapper .question:hover p, 
.faq-wrapper .question:focus p {
  color: var(--primary_color);
}

.faq-wrapper .accordion .dropdown {
  transition: all 300ms cubic-bezier(0,1,0,1);
  overflow: hidden;
}

.faq-wrapper .accordion .dropdown.drop {
  transition: all 300ms cubic-bezier(0,1,0,1);
  transform: translateY(-50%);
  height: 0;
}

.faq-wrapper .accordion .dropdown p {
  padding: 10px 20px 10px 0;
  font-size: 1rem;
  padding-bottom: 2rem;
}

/* ======= MEDIA QUERY ======= */
@media screen and (max-width: 600px) {
  #faq .faq-wrapper {
    padding: 0 2rem;
    width: 100%;
  }
}
