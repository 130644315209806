#give_showcase {
  position: relative;
  background: url("../../assets/images/give-offering.jpg") no-repeat center
    center/cover;
  min-height: 40rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

#give_showcase:before {
  content: "";
  position: absolute;
  inset: 0;
  background: rgba(37, 37, 37, 0.6);
  z-index: 0;
}

.give_showcase_wrapper {
  z-index: 1;
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4rem;
}

.give_showcase_wrapper .left {
  justify-self: flex-start;
}

.give_showcase_wrapper .left,
.give_showcase_wrapper .right {
  width: 100%;
}

.give_showcase_wrapper p {
  color: #fff;
}

.give_showcase_wrapper .left p:first-child,
.give_showcase_wrapper .right p:first-child {
  font-size: 2.5rem;
  line-height: 1.3;
  font-weight: 400;
  padding: 1rem 0;
}

.give_showcase_wrapper .right img {
  width: 200px;
  margin-top: 1rem;
  border-radius: 10px;
  margin: 2rem 0;
}

.give_showcase_wrapper .donate_link {
  border: 2px solid #fff;
  color: #fff;
}

.arrow_down {
  position: absolute;
  bottom: 0;
  z-index: 2;
}

.arrow_down a {
  font-size: 5rem;
  animation: arrow_bounce 1000ms ease-in-out alternate infinite;
  transition: opacity 300ms ease;
}

.arrow_down a:hover,
.arrow_down a:focus {
  opacity: 0.6;
}

@keyframes arrow_bounce {
  0% {
    transform: translateX(20px);
  }

  100% {
    transform: translateX(-20);
  }
}

/* ======== MEDIA QUERY ======= */
@media screen and (max-width: 800px) {
  .give_showcase_wrapper {
    flex-direction: column;
  }

  .give_showcase_wrapper .left p:nth-child(2) {
    max-width: 500px;
  }
}

@media screen and (max-width: 600px) {
  #give_showcase {
    height: 100vh;
  }

  .give_showcase_wrapper {
    max-width: 100%;
    align-items: center;
    justify-content: center;
    gap: 3rem;
  }

  .give_showcase_wrapper .left p:first-child,
  .give_showcase_wrapper .right p:first-child {
    font-size: 2rem;
  }
}
