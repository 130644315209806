.e-header {
    min-height: 20vh;
    background-color: var(--black_color);
    display: flex;
    justify-content: center;
    align-items: center;
}

.e-header .container {
    display: flex;
    justify-content: center;
    align-items: center;
}



.e-header .container .eventHeader h1 {
    color: #fff;
    font-size: 4rem;
    text-transform: uppercase;
    text-align: center;
}

.event {
    min-height: 50vh;
}

.event .container {
    padding: 0 10%;

}

.event .container .topWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

.event .container .topWrapper .tw-holder {
    width: 50%;

}

@media screen and (max-width:600px) {
    .event .container .topWrapper .tw-holder {
        width: 100%;

    }
}

.event .container .topWrapper .topHeader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-transform: capitalize;
    margin-top: 3rem;
}

.event .container .topWrapper .topHeader h1 {
    font-size: 3rem;
}



.event .container .topWrapper .search {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 4rem;
}

.event .container .topWrapper .search span {
    position: absolute;
    left: 0;
    color: #000;
    font-size: 1.3rem;
    padding-left: 0.5rem;
    pointer-events: none;
}

.event .container .topWrapper .search input {
    width: 100%;
    color: #000;
    font-size: 1.2rem;
    height: 50px;
    background-color: transparent;
    border: none;
    outline: 0;
    border-bottom: 1px solid #000;
}

.event .container .topWrapper .search input:focus~span {
    display: none;
}

.event .container .topWrapper .search .search_icon {
    position: absolute;
    right: 0;
    color: #000;
    font-size: 2rem;
    cursor: pointer;
}

.event .container .eventListItem {
    display: flex;
    flex-direction: column;
    margin: 2rem 0;

}

.event .container .eventListItem .top {
    display: flex;
    /* border: solid red 2px; */
}

.event .container .eventListItem .top .day {
    flex: 1;
    display: flex;
    justify-content: baseline;
    align-items: flex-end;
    bottom: 0;
    /* border: solid blue 2px; */
}

.event .container .eventListItem .top .day h1 {
    text-transform: uppercase;
    font-size: 2.5rem;
}

.event .container .eventListItem .top .date {
    flex: 6;
    display: flex;
    justify-content: baseline;
    align-items: flex-end;
    padding-bottom: 1.4%;
    /* border: solid red 2px; */
}

.event .container .eventListItem .top .date small {
    font-weight: 500;
    text-transform: uppercase;
}

.event .container .eventListItem .bottom {
    display: flex;
    gap: 2%;
    background-color: #EFEFEF;
}

.event .container .eventListItem .bottom .left {
    flex: 1;
    color: #fff;
    background-color: var(--black_color);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1%;
}

.event .container .eventListItem .bottom .left p {
    font-weight: 600;
    text-transform: uppercase;
    text-align: center;
    font-size: 1.2rem;
}

.event .container .eventListItem .bottom .right {
    flex: 20;
    padding: 10px 0;
}



.event .container .eventListItem .bottom .right .eventName .links {
    color: var(--secondary-color);
    font-size: 2rem;
    font-weight: 500;
    text-transform: capitalize;
}

.event .container .eventListItem .bottom .right .eventDetails {
    display: flex;
}



.event .container .eventListItem .bottom .right .col {
    flex: 1;
    display: flex;
    text-transform: capitalize;
}

@media screen and (max-width: 600px) {
    .event .container .eventListItem .bottom .right .eventDetails {
        flex-direction: column;
    }

    .event .container .eventListItem .bottom .right .col {
        flex-direction: column;
    }

    .event .container {

        padding: 0 3%;
    }
}

.event .container .eventListItem .bottom .right .col span {
    padding-right: 1rem;
}

.event .container .eventListItem .bottom .right .col .links {
    color: var(--secondary-color);
    font-weight: 500;
    text-transform: capitalize;
}