#new_showcase {
  background: url("../../assets/images/church/IMG_8694.jpg") no-repeat center center/cover;
  height: auto;
  position: relative;
  /* padding: 1rem; */
}

#new_showcase:after {
  content: "";
  position: absolute;
  inset: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 0;
}

#new_showcase .showcase_wrapper {
  height: 35rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

#new_showcase .showcase_wrapper h1 {
  font-size: var(--head_font);
  color: #fff;
  z-index: 1;
}

/* ======= SECTIOIN : EXPECT =======  */

#new_expect {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 2.5rem;
  min-height: 450px;
  /* border: solid red 2px; */
}

#new_expect .left {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateY(-7rem);
}

#new_expect img {
  height: 350px;
  object-fit: contain;
}

#new_expect .right {
  width: 100%;
  padding-top: 3rem;
  /* border: solid red 2px; */
}

#new_expect .right h2 {
  text-transform: uppercase;
}

#new_expect .right p {
  line-height: 1.5;
  margin: 0.5rem 0;
  max-width: 400px;
}

#new_expect .new_expect_links {
  display: flex;
  align-items: center;
  gap: 2rem;
  margin: 0.5rem 0;
}

#new_expect .right .linkAnima {
  color: #000;
  text-transform: uppercase;
  font-weight: 700;
  justify-content: left;
  padding: 0;
  width: fit-content;
}

#new_expect .right .linkAnima:hover {
  color: var(--primary_color);
}

#new_expect .left .shape_2 {
  clip-path: polygon(0 0, 100% 0%, 79% 100%, 0% 100%);
  height: 270px;
  width: 103%;
  background-color: var(--primary_color);
  position: absolute;
  left: 0;
  bottom: -3rem;
  z-index: -1;
}

/* ======= section : NEW_ABOUT ======= */

#new_about .new_about_wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 2.5rem;
  min-height: 450px;
}

#new_about .right {
  width: 100%;
  height: 100%;
  position: relative;
  /* transform: translateY(-7rem); */
}

#new_about img {
  height: 350px;
}

#new_about .left {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  /* padding-top: 3rem; */
  /* transform: translateY(-7rem); */
}

#new_about .left h2 {
  text-transform: uppercase;
}

#new_about .left p {
  line-height: 1.5;
  margin: 0.5rem 0;
  max-width: 400px;
}

#new_about .new_expect_links {
  display: flex;
  align-items: center;
  gap: 2rem;
  margin: 0.5rem 0;
}

#new_about .left .linkAnima {
  color: #000;
  text-transform: uppercase;
  font-weight: 700;
  justify-content: left;
  padding: 0;
  width: fit-content;
}

#new_about .left .linkAnima:hover {
  color: var(--primary_color);
}

#new_about .right .shape_2 {
  /* clip-path: polygon(0 0, 100% 0%, 79% 100%, 0% 100%); */
  -webkit-clip-path: polygon(0 0, 100% 0%, 100% 100%, 23% 99%);
  clip-path: polygon(0 0, 100% 0%, 100% 100%, 23% 99%);
  height: 270px;
  width: 103%;
  background-color: var(--primary_color);
  position: absolute;
  right: 0;
  bottom: -3rem;
  z-index: -1;
}

.am_new_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2rem 0;
}

/* 
.am_new_btn li {
  display: flex;
  align-items: center;
  justify-content: center;
}

.am_new_btn .new_submit {
  border-radius: 0;
  font-size: 5rem;
} */
#contact_form .form_wrapper .am_new_btn input[type="submit"] {
  background-color: #fff;
  width: 150px;
  height: 60px;
  text-align: center;
  cursor: pointer;
  border-radius: 0px;
  border: 1px solid var(--primary_color);
  background-color: var(--primary_color);
  color: #fff;
  font-size: 1rem;
  font-weight: 600;
  transition: all var(--transition);
}

#contact_form .form_wrapper input[type="submit"]:hover {
  background-color: #fff;
  color: #000;
}

#map {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

#map .ask {
  margin-bottom: 3rem;
}

#map a {
  color: var(--primary_color);
  font-weight: 600;
  line-height: 1.3;
  padding: 1rem;
}

#map a:hover {
  color: #fff;
}

/* ======= MEDIA QUERY ======= */

@media screen and (max-width: 1000px) {

  /* ======== Expeect Section ======= */
  #new_expect {
    max-width: 85%;
    margin: auto;
    flex-direction: column;
  }

  #new_expect .left {
    transform: translateY(-3rem);
  }

  #new_expect .right {
    transform: translateY(-3rem);
  }

  #new_expect .right p {
    max-width: 100%;
  }

  #new_expect img {
    height: auto;
  }

  #new_expect .left .shape_2 {
    left: -10%;
    bottom: -2rem;
    width: 118%;
  }

  /* ======= SECTION: EXPECT_ABOUT ======= */
  #new_about .new_about_wrapper {
    max-width: 85%;
    margin: auto;
    flex-direction: column-reverse;
  }

  #new_about img {
    height: auto;
  }

  #new_about .left p {
    max-width: 100%;
  }

  #new_about .right .shape_2 {
    left: -10%;
    bottom: -2rem;
    width: 118%;
  }
}

/* ======= Max-width: 600px ======= */

@media screen and (max-width: 600px) {

  /* ======== Expeect Section ======= */
  #new_expect {
    max-width: 100%;
    padding: 1rem;
  }

  #new_expect .right p {
    max-width: 100%;
  }

  #new_expect .left .shape_2 {
    clip-path: polygon(0 0, 100% 0%, 79% 100%, 0% 100%);
    height: 70%;
    bottom: -1rem;
  }

  /* ======= SECTION: EXPECT_ABOUT ======= */
  #new_about .new_about_wrapper {
    max-width: 100%;
    padding: 1rem;
  }

  #new_about .left p {
    max-width: 100%;
  }

  #new_about .right .shape_2 {
    clip-path: polygon(0 0, 100% 0%, 79% 100%, 0% 100%);
    height: 70%;
    bottom: -1rem;
  }
}