#contact_showcase {
  position: relative;
  height: 30rem;
  background: url("../../assets/images/contact2.jpg") no-repeat center
    center/cover;
  display: flex;
  align-items: center;
  justify-content: center;
  isolation: isolate;
}

#contact_showcase::after {
  content: "";
  position: absolute;
  inset: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: -1;
}

#contact_showcase .showcase_wrapper h2 {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: var(--head_font);
}

/* ======= CARD_ADDRESS SECTION ======= */
#contact_address {
  position: relative;
  margin-bottom: 17rem;
}

#contact_address .contact_wrapper {
  background-color: #949494;
  width: 630px;
  min-height: 200px;
  margin-left: auto;
  padding-left: 2rem;
  position: absolute;
  right: 0;
  top: -2rem;
}

#contact_address .card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #ebe3e3;
  width: 600px;
  padding: 3rem 2rem;
  height: 100%;
  position: absolute;
  right: 0;
  top: -3rem;
}

#contact_address .card p a {
  color: var(--primary_color);
  font-weight: 600;
  text-decoration: underline;
  transition: color var(--transition);
}

#contact_address .card p a:hover {
  color: #fff;
}

/* ======= CONTACT_PRAYER SECTION ======= */
#contact_prayer {
  background: url("../../assets/images/prayer.jpg") no-repeat center
    center/cover;
  background-attachment: fixed;
  position: relative;
  min-height: 300px;
  display: flex;
  justify-content: center;
  padding: 1rem;
}

#contact_prayer::before {
  content: "";
  position: absolute;
  inset: 0;
  background: rgba(0, 0, 0, 0.7);
  z-index: 0;
}

#contact_prayer .prayer_section {
  z-index: 1;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

#contact_prayer .prayer_section {
  position: relative;
  z-index: 1;
  color: #fff;
}

#contact_prayer .prayer_section h2 {
  font-size: 2rem;
}

#contact_prayer .prayer_section a {
  color: #fff;
}

/* ====== CONTACT_FORM SECTION ======= */
#contact_form {
  padding: 3rem 0;
}

#contact_form .form_wrapper ul {
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
}

#contact_form .form_wrapper label {
  font-weight: 600;
  font-size: 18px;
  color: #5a5a5a;
}

#contact_form .form_wrapper label span {
  font-size: 1.3rem;
  font-weight: 700;
  color: red;
}

#contact_form .form_wrapper input,
#contact_form .form_wrapper select,
#contact_form .form_wrapper textarea {
  display: block;
  width: 500px;
  border: 1px solid #aaaaaa;
  outline: none;
  font-size: 1.2rem;
  color: #aaaaaa;
}

#contact_form .form_wrapper input:focus,
#contact_form .form_wrapper select:focus {
  box-shadow: inset 1px 1px 5px 0px rgba(209, 209, 209, 0.5),
    inset -1px -1px 5px 0px rgba(197, 197, 197, 0.5);
}

#contact_form .form_wrapper input {
  height: 40px;
}

#contact_form .form_wrapper input[type="email"],
#contact_form .form_wrapper select {
  width: 500px;
}

#contact_form .form_wrapper li .name_input {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

#contact_form .form_wrapper li .name_input label {
  font-size: 14px;
  font-weight: 400;
}

#contact_form .form_wrapper li .phone_input {
  display: flex;
  flex-direction: row;
}

#contact_form .form_wrapper li .phone_input .dash {
  width: 10px;
  height: 1px;
  background-color: #5a5a5a;
  margin: 0.5rem;
}

#contact_form .form_wrapper li .radio_campus span {
  display: flex;
  gap: 0.7rem;
}

#contact_form .form_wrapper li .radio_campus span label {
  font-weight: 400;
}

#contact_form .email_input {
  display: flex;
  align-items: center;
  gap: 1rem;
}

#contact_form input::-webkit-outer-spin-button,
#contact_form input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

#contact_form input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}
/* #contact_form .form_wrapper input[type="checkbox"] {

} */

#contact_form .form_wrapper input[type="submit"] {
  background-color: #fff;
  width: 100px;
  height: 40px;
  text-align: center;
  cursor: pointer;
  border-radius: 5px;
  border: 1px solid var(--primary_color);
  background-color: var(--primary_color);
  color: #fff;
  font-size: 1rem;
  font-weight: 600;
  transition: all var(--transition);
}

#contact_form .form_wrapper input[type="submit"]:hover {
  background-color: #fff;
  color: #000;
}

@media screen and (max-width: 1100px) {
  #contact_form .form_wrapper li .name_input {
    flex-direction: column;
  }

  #contact_form .form_wrapper input,
  #contact_form .form_wrapper select {
    width: 100%;
  }

  #contact_form .form_wrapper input[type="email"] {
    width: 100%;
  }

  #contact_form .form_wrapper li textarea {
    width: 100%;
  }
}

@media screen and (max-width: 650px) {
  #contact_showcase .showcase_wrapper h2 {
    font-size: 2rem;
  }

  #contact_address {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  #contact_address .contact_wrapper {
    width: 100%;
  }

  #contact_address .card {
    width: 100%;
    padding: 1rem;
    gap: 0.7rem;
    line-height: 1.2;
  }

  #contact_prayer .container {
    max-width: 100%;
  }

  #contact_prayer .prayer_section h2 {
    font-size: 1.5rem;
    line-height: 1.3;
  }

  #contact_form .form_wrapper {
    max-width: 100%;
    padding: 1rem;
  }
}
