#about_showcase {
  position: relative;
  background: url("../../assets/images/easterService.jpg") no-repeat center
    center/cover;
  min-height: 40rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
}

#about_showcase::before {
  content: "";
  position: absolute;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 0;
}

#about_showcase .showcase_wrapper {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
}

#about_showcase h1 {
  color: #fff;
  font-size: 3rem;
}

#about_showcase .date {
  color: #fff;
  font-size: 1.8rem;
  font-weight: 700;
}

.about_welcome {
  min-height: 25rem;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  gap: 1rem;
  position: relative;
}

.about_welcome .left {
  align-self: flex-start;
  padding: 1rem 0;
  width: 85%;
}

.about_welcome .left p {
  width: 300px;
  padding: 1rem 0;
}

.about_welcome .left p:first-child {
  font-size: 2rem;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 1.3;
}

.about_welcome .left a {
  color: #000;
  margin-left: 0;
  font-weight: 700;
  text-transform: uppercase;
}

.about_welcome .left a:focus {
  color: #fff;
}

.about_welcome .left a:hover {
  color: #fff;
}

.about_welcome .right {
  width: 60%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1.3rem;
  padding: 0 1rem;
  position: absolute;
}

.about_welcome .right .card {
  position: relative;
  height: 25rem;
  width: 100%;
  border-radius: 0.5rem;
  overflow: hidden;
  bottom: 3rem;
  box-shadow: 0 6px 10px rgb(0 0 0 / 8%), 0 0 6px rgb(0 0 0 / 5%);
}

.about_welcome .right .card:hover .text {
  transform: translate(0rem);
  background-color: var(--grey_color);
  height: 60%;
}

.about_welcome .right .card img {
  height: 100%;
  object-fit: cover;
  object-position: center center;
}

.about_welcome .right .text {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  position: absolute;
  bottom: 0;
  height: 50%;
  width: 100%;
  transition: var(--transition);
  transform: translateY(7rem);
}

.about_welcome .right .text p {
  font-size: 16px;
  color: #fff;
  margin-bottom: 0.5rem;
  display: inline-block;
}

.about_welcome .right .text p:first-child {
  font-size: 1.3rem;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 1.3;
  margin-bottom: 2.8rem;
}

.about_welcome .right .card:hover .text p:first-child {
  margin-bottom: 0;
  line-height: 1.3;
}

.about_welcome .right .text a {
  display: flex;
  justify-content: flex-start;
  padding: 0;
  flex-direction: row;
  font-size: 1rem;
  font-weight: 600;
  text-align: center;
}

.about_welcome .right .shape {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: -1;
  clip-path: polygon(0 0, 100% 0%, 100% 100%, 20% 100%);
  height: 15rem;
  width: 100%;
  background-color: var(--primary_color);
}

#aurora_events {
  max-width: 70%;
  height: 300px;
  margin: auto;
  display: flex;
  align-items: center;
}

#aurora_events h1 {
  font-size: var(--head_font);
}

/* ======= MEDIA QUERY ======= */

@media screen and (max-width: 1200px) {
  .about_welcome .left {
    width: 100%;
  }

  .about_welcome .right {
    width: 68%;
  }
}

@media screen and (max-width: 1000px) {
  .about_welcome {
    flex-direction: column-reverse;
    max-width: 85%;
    margin: auto;
  }

  .about_welcome .left {
    align-self: center;
    margin: 4rem;
    width: 100%;
  }

  .about_welcome .left p {
    width: 100%;
  }

  .about_welcome .right {
    flex-direction: column;
    width: 100%;
    gap: 2rem;
    position: unset;
  }

  .about_welcome .right .card img {
    min-width: 100%;
    object-fit: cover;
    object-position: center center;
  }

  .about_welcome .right .text {
    height: 60%;
    transform: translateY(0);
    background-color: var(--grey_color);
  }

  .about_welcome .right .text p:first-child {
    margin-bottom: 0;
  }

  .about_welcome .right .shape {
    bottom: 20rem;
  }

  /* ======= EVENT SECTION ======= */
  #aurora_events {
    max-width: 85%;
    height: 100px;
  }

  #aurora_events h1 {
    font-size: 2em;
  }
}

/* ====== About_Showcase ====== */
@media screen and (max-width: 600px) {
  #about_showcase h1 {
    font-size: 2.5rem;
  }

  #about_showcase .date {
    font-size: 1.5rem;
  }

  .about_welcome {
    max-width: 100%;
  }

  .about_welcome .left {
    margin-top: 0;
    margin-bottom: 3rem;
    padding: 1rem;
  }

  .about_welcome .right .shape {
    bottom: 20rem;
    height: 160px;
    margin-bottom: 2rem;
  }

  /* ======= EVENT SECTION ======= */

  #aurora_events h1 {
    font-size: 1.5em;
  }
}
