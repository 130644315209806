/* showcase */

.swma-group-showcase {
  background: url("../../assets/images/church/IMG_8968.jpeg") no-repeat center center/cover;
  height: 90vh;
}

.min-group-case {
  background: rgba(0, 0, 0, 0.6);
  height: 100%;
}

.min-group-txt {
  color: #fff;
  text-align: center;
  font-weight: 700;
  position: absolute;
  top: 40%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}

/* @media screen and (max-width: 600px) {
    .min-group-showcase {
        height: 70vh;
    }
} */

/* showcase end */

.kidsWrapper {
  width: 100%;
  min-height: 40vh;
  display: flex;
  justify-content: space-between;
  gap: 2%;
  padding: 4rem 2%;
}

@media screen and (max-width:600px) {
  .kidsWrapper {
    flex-direction: column;
  }
}

.kidsWrapper .col {
  flex: 1;
  height: 25rem;
  position: relative;
}

.kidsWrapper .col .txtContent h1 {
  text-transform: uppercase;
  font-size: 3rem;
}

.kidsWrapper .col .imgHolder {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: relative;

}

.kidsWrapper .col .imgHolder img {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  position: relative;
}

.kidsWrapper .col .imgHolder:hover img {
  filter: saturate(0);
}

.kidsWrapper .col .imgHolder .imgOverlay {
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: var(--secondary-color);
  opacity: .8;
  top: 0;
  left: 0;
  position: absolute;
  visibility: hidden;
}

.kidsWrapper .col .imgHolder:hover .imgOverlay {
  visibility: visible;
  cursor: pointer;
  transition: transform var(--transition);
}

.kidsWrapper .col .imgTitle h2 {
  text-transform: uppercase;
  font-size: medium;
  color: var(--light-gray);
  padding: 0.5rem 0;
}

.connect {
  min-height: 60vh;
  background-color: var(--grey_color);
  margin-top: 2rem;
}

.connect .container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 59vh;
  gap: 4%;
}

@media screen and (max-width:600px) {
  .connect .container {
    flex-direction: column;
  }
}

.connect .container .col {
  flex: 1;
  /* border: solid red 2px; */
}

.connect .container .col .contentWrapper h1 {
  color: #fff;
  text-transform: uppercase;
  font-size: 3rem;
}

.connect .container .col .contentWrapper h4 {
  color: #fff;
  font-size: 1.5rem;
}

.connect .container .col .contentWrapper .btn {
  border-color: #fff;
  color: #fff;
}



.connect .container .col .contentWrapper .bottom .social {
  display: flex;
}

.connect .container .col .contentWrapper .bottom .social a {
  font-size: 2rem;
  padding: 1rem 1rem 1rem 0;
  transition: var(--transition);
}

.connect .container .col .contentWrapper .bottom .social a:hover,
.connect .container .col .contentWrapper .bottom .social a:focus {
  color: var(--primary_color);
}

.whoWeAre {
  min-height: 60vh;
  margin: 4rem 0;
}

.whoWeAre .container {
  display: flex;
  gap: 4%;
}

@media screen and (max-width:600px) {
  .whoWeAre .container {
    flex-direction: column;
  }
}

.whoWeAre .container .col {
  flex: 1;
}

.whoWeAre .container .col .contentWrapper .imgHolder {
  width: 100%;
  height: 100%;
}

.whoWeAre .container .col .contentWrapper .imgHolder img {
  filter: saturate(0);
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.whoWeAre .container .col .contentWrapper .imgHolder img:hover {
  filter: unset;
}

.whoWeAre .container .col .contentWrapper h1 {
  text-transform: uppercase;
  font-size: 3rem;
}

.whoWeAre .container .col .contentWrapper p {
  width: 70%;
  margin-top: 2rem;
}

.upcomingEvents {
  background-color: #efefef;
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.upcomingEvents .container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}



.upcomingEvents .container h1 {
  text-transform: uppercase;
  font-size: 3rem;
  color: var(--secondary-color);
}

.upcomingEvents .container .eventsList {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

@media screen and (max-width:600px) {
  .upcomingEvents .container {
    flex-direction: column;
  }

  .upcomingEvents .container .eventsList {

    grid-template-columns: repeat(1, 1fr);
  }
}

.upcomingEvents .container .eventsList .eventItem {
  position: relative;
  font-weight: 500;
  padding: 0.75rem 2.3rem;
  margin: 0.75rem;
  border: 1px solid var(--secondary-color);
  transition: transform var(--transition);
  transition: all .3s ease-in;
  color: var(--grey_color);
  text-transform: uppercase;
  min-width: 20rem;
  text-align: center;
}

.upcomingEvents .container .eventsList .eventItem:hover {
  color: #fff;
  background-color: var(--secondary-color);
}

.upcomingEvents .container .eventsList .eventItem::before {
  content: "";
  position: absolute;
  z-index: -1;
  inset: 0;
  padding: 0.75rem 2.3rem;
  background-color: var(--secondary-color);
  transform-origin: top;
  transition: transform var(--transition);
  transform: scaleX(0);
}

.upcomingEvents .container .eventsList .eventItem:hover::before,
.upcomingEvents .container .eventsList .eventItem:focus::before {
  transform: scaleX(1);
}

.upcomingEvents .container .links {
  color: var(--grey_color);
}